import { trackingKaminoURL } from '../../configuration/Configuration.js';
import rejectNonOkResponse from '../../scripts/api/rejectNonOkResponse.js';

const getUrlParams = (url) => {
  try {
    if (!url) return {}; // Return empty object if URL is null or undefined
    const parsedUrl = new URL(url);
    return Array.from(new URLSearchParams(parsedUrl.search)).reduce(
      (acc, [key, value]) => ({ ...acc, [key]: value }),
      {}
    );
  } catch {
    return {}; // Return empty object if the input is not a valid URL
  }
};

export default async (urlTracking, jsonQueryParams, thirdPartyTracking = null) => {
  const res = (
    await rejectNonOkResponse(
      fetch(trackingKaminoURL, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          urlTracking,
          jsonQueryParams: JSON.stringify(jsonQueryParams),
        }),
      })
    )
  ).text();

  // set params and call thirdPartyTracker */
  const urlTrackingParams = getUrlParams(urlTracking);
  var params = {
    type: urlTrackingParams.tt.toLowerCase(),
    campaignId: urlTrackingParams.cid,
    lineItemId: urlTrackingParams.liid,
    creativeId: urlTrackingParams.crid,
    ...getUrlParams(thirdPartyTracking),
    ...jsonQueryParams,
  };
  params['$cb'] = Date.now();
  window.kminoTracking.enqueue(params);

  return res;
};
