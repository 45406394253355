import * as pageScroll from '../../helpers/view/pagescroll.js';
import './overlay.less';
import parseHTMLFragment from '../../helpers/dom/parseHTMLFragment.js';
import loaderHTML from '../../images/loader.svg?source';
import loader2025HTML from '../../images/loader-v2025.svg?source';
import asStringArray from '../../helpers/type/as-string-array.js';

const CSS_PREFIX = 'f-overlay';
const CLASSNAME_FRAMED = `${CSS_PREFIX}--framed`;
const CLASSNAME_VISIBLE = 'visible';

/**
 * Overlay Possible modifiers: all, menu, menuTab, header (see ./overlay.less)
 *
 * @example
 *   const loader = new Overlay({ modifier: 'all', spinner: false });
 *   loader.addEventListener('close', () => console.log('overlay closed'));
 *   loader.show();
 *   loader.hide();
 */
export default class Overlay extends EventTarget {
  #options;
  #overlay;

  constructor(options = {}) {
    super();

    const {
      closeOnClick = false,
      closeTransition = true,
      container = null,
      disableScroll = false,
      message = null,
      modifier = null,
      spinner = true,
    } = options;

    this.#options = {
      closeOnClick,
      closeTransition,
      container: container instanceof Element ? container : document.body,
      disableScroll,
      message,
      modifiers: new Set(modifier != null ? asStringArray(modifier) : []),
      spinner,
    };
  }

  get active() {
    return !!this.#overlay;
  }

  show() {
    if (this.#overlay) {
      this.remove();
    }

    const { container, closeOnClick, modifiers, disableScroll, spinner, message } = this.#options;

    const className = [
      CSS_PREFIX,
      ...Array.from(modifiers, (m) => `${CSS_PREFIX}--${m}`),
      ...(container !== document.body ? [CLASSNAME_FRAMED] : []),
      CLASSNAME_VISIBLE,
    ].join(' ');

    this.#overlay =
      parseHTMLFragment(`<div class="${className}" role="status" aria-live='polite' aria-label='Chargement en cours' tabindex="-1" data-automation-id="fnac-spinner">
  ${message ? `<p class="${CSS_PREFIX}__message">${message}</p>` : ''}
</div>`).firstElementChild;

    if (spinner) {
      // We use import loader source instead of URL (with ?source) due to Firefox, when we inject an image just before navigation, it shows a broken image
      // If we preload it before, Firefox freeze the image (no animation)
      // Instead we render the SVG in the DOM
      /** @type {SVGElement} */
      const loader = parseHTMLFragment(modifiers.has('v2025') ? loader2025HTML : loaderHTML).firstElementChild;
      // loader is a SVGElement, so we need to use baseVal for properties
      loader.classList.add(`${CSS_PREFIX}__loader`);
      loader.classList.toggle(`${CSS_PREFIX}__loader--v2025`, modifiers.has('v2025'));
      loader.role = 'img';
      loader.ariaHidden = 'true';
      loader.setAttribute('focusable', 'false');
      this.#overlay.prepend(loader);
    }

    container.appendChild(this.#overlay);

    if (disableScroll) {
      pageScroll.lock();
    }

    if (closeOnClick) {
      this.#overlay.addEventListener('click', () => this.hide());
    }
  }

  async hide() {
    if (!this.#overlay) return;

    this.#overlay.classList.remove(CLASSNAME_VISIBLE);

    const { disableScroll, closeTransition } = this.#options;

    if (disableScroll) {
      pageScroll.unlock();
    }

    if (closeTransition) {
      await new Promise((resolve) => {
        this.#overlay.addEventListener('transitionend', () => resolve());
      });
    }

    this.remove();

    this.dispatchEvent(new Event('close', { bubbles: true }));
  }

  remove() {
    // FIXME .hide() + .remove() race condition (hide could be async)
    this.#overlay?.remove();
    this.#overlay = null;
  }
}
