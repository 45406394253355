import componentRegistry from '../../registry.js';
import Kamino from '../kamino.js';
import './index.less';

/** @param {HTMLElement} el The DOM element */
function getTranslateXY(el) {
  const style = window.getComputedStyle(el);
  const matrix = new DOMMatrixReadOnly(style.transform);
  return {
    translateX: matrix.m41,
    translateY: matrix.m42,
  };
}

/**
 * @param {HTMLElement} el The DOM element
 * @param {string} translation Translation string use for css transform
 */
const translateNoTransition = (el, translation) => {
  el.style.transition = 'none';
  el.style.transform = translation;
  el.offsetHeight; // force repaint
  el.style.transition = '';
};

class ImpulseCarousel {
  #isBusy = false;
  #productList;
  #popin;
  #currIndex = 0;
  #childrenCount;
  #blockSize;
  #initialTranslation;
  #resizeListener;
  #windowWidth;
  #paginationBullets;
  #paginationActiveBullet;

  constructor({ el }) {
    this.el = el;
    this.#popin = el.querySelector('#js-kamino-impulse-popin');
    this.#productList = this.#popin.querySelector('.f-kamino-products');
    this.#childrenCount = this.#productList.children.length;
    this.#resizeListener = false;

    // set carousel when popin is open
    el.querySelector('.f-kamino-wrapper.js-GetPopin')?.addEventListener('popin:open', () => {
      this.#popin.dataset.carouselDirection == 'horizontal' ? this.initHorizontal() : this.initVertical();
    });

    // set pagination carousel bullets
    this.#paginationBullets = [...this.#popin.querySelectorAll('.f-kamino-carousel-pagination li button')];
    this.#paginationBullets.forEach((bullet) => {
      bullet.addEventListener('click', () => this.goToIndex(bullet.dataset.index));
    });

    // add copy at the end
    for (let ci = 0; ci < this.#childrenCount; ci++) {
      let cln = this.#productList.children[ci].cloneNode(true);
      this.#productList.append(cln);
    }
    // add copy at the begining
    for (let ci = 0; ci < this.#childrenCount; ci++) {
      let pos = this.#childrenCount - 1;
      let cln = this.#productList.children[pos].cloneNode(true);
      this.#productList.prepend(cln);
    }

    this.el.dispatchEvent(
      new CustomEvent('infinite-carousel:init', {
        bubbles: true,
      })
    );
    const onResizeBinded = this.onResizeHorizontal.bind(this);
    const setDataDirection = (mediaQuery, el) => {
      if (this.#resizeListener) {
        window.removeEventListener('resize', onResizeBinded);
        this.#resizeListener = false;
      }
      if (mediaQuery.matches) {
        el.setAttribute('data-carousel-direction', 'horizontal');
        this.initHorizontal();

        if (this.#resizeListener == false) {
          this.#resizeListener = true;
          window.addEventListener('resize', onResizeBinded);
        }
      } else {
        el.setAttribute('data-carousel-direction', 'vertical');
        this.initVertical();
      }
      translateNoTransition(this.#productList, this.#initialTranslation);
    };

    var mediaQuery = window.matchMedia('(max-width: 1025px)');
    setDataDirection(mediaQuery, this.#popin);
    mediaQuery.addEventListener('change', () => {
      setDataDirection(mediaQuery, this.#popin);
    });
    this.#productList.addEventListener('wheel', this.scrollCarousel.bind(this));
  }

  onResizeHorizontal() {
    if (this.#windowWidth !== window.innerWidth) {
      this.#blockSize = this.#productList.children[this.#childrenCount].offsetWidth;
      this.#initialTranslation = `translateX(${this.#blockSize * (this.#currIndex + this.#childrenCount) * -1}px)`;
      translateNoTransition(this.#productList, this.#initialTranslation);
      this.#windowWidth = window.innerWidth;
    }
  }

  initHorizontal() {
    this.#currIndex = 0;
    this.#blockSize = this.#productList.children[this.#childrenCount].offsetWidth;
    this.#initialTranslation = `translateX(${this.#blockSize * this.#childrenCount * -1}px)`;
    this.#productList.style.transform = this.#initialTranslation;
    this.setPaginationBullets();
  }

  initVertical() {
    this.#currIndex = 0;
    this.#blockSize = this.#productList.children[this.#childrenCount].offsetHeight;
    this.#initialTranslation = `translateY(${this.#blockSize * this.#childrenCount * -1}px)`;
    this.#productList.style.transform = this.#initialTranslation;
    this.setPaginationBullets();
  }

  scrollCarousel(e) {
    if (this.#isBusy) return;
    this.#isBusy = true;
    setTimeout(() => {
      this.#isBusy = false;
    }, 400);

    const index = e.deltaY < 0 ? this.#currIndex + 1 : this.#currIndex - 1;
    this.goToIndex(index);
  }

  setPaginationBullets() {
    const index = Math.abs((this.#currIndex + this.#childrenCount) % this.#childrenCount);
    this.#paginationActiveBullet?.classList.remove('is-active');
    this.#paginationBullets[index].classList.add('is-active');
    this.#paginationActiveBullet = this.#paginationBullets[index];
  }

  goToIndex(index) {
    index = parseInt(index);
    const eventToDispatch = new CustomEvent(`infinite-carousel:${index < this.#currIndex ? 'prev' : 'next'}`, {
      bubbles: true,
      detail: { index },
    });

    this.el.dispatchEvent(eventToDispatch);

    const translateString = this.#popin.dataset.carouselDirection == 'horizontal' ? 'translateX' : 'translateY';
    const transitionDuration = parseFloat(getComputedStyle(this.#productList)['transitionDuration']);
    const actualTranslation = getTranslateXY(this.#productList);
    const translation =
      this.#popin.dataset.carouselDirection == 'horizontal'
        ? actualTranslation.translateX
        : actualTranslation.translateY;

    const move = this.#blockSize * (this.#currIndex - index);
    this.#productList.style.transform = `${translateString}(${translation + move}px)`;
    this.#currIndex = index;
    if (Math.abs(this.#currIndex) == this.#childrenCount) {
      setTimeout(() => {
        translateNoTransition(this.#productList, this.#initialTranslation);
      }, transitionDuration * 800);

      this.#currIndex = 0;
    }
    this.setPaginationBullets();
  }
}

export default class KaminoImpulse extends Kamino {
  #isExpand;
  #impulseExpand;
  #video;
  #popin;
  #image;
  #popinVideo;
  trackingImp;
  trackingView;
  trackingLoad;
  trackingClick;
  trackingVideo;
  trackingPview;

  constructor({ el }) {
    super({ el, autoInitTracking: false });
    this.#impulseExpand = el.querySelector('.js-kamino-expand');
    this.#popin = el.querySelector('.f-kamino-wrapper.js-GetPopin');
    this.#video = el.querySelector('video');
    this.#image = el.querySelector('.js-kamino-image');
    this.#popinVideo = el.querySelector('#js-kamino-impulse-popin video');
    this.#isExpand = true;
    this.trackingClick = el.dataset.trackingClick;
    this.trackingVideo = el.dataset.trackingVideo;
    this.trackingView = el.dataset.trackingView;
    this.trackingPview = el.dataset.trackingPview;

    this.#image?.addEventListener('click', this.#onClickImage.bind(this));
    this.#video?.addEventListener('click', () => {
      super.postTracking(this.trackingClick, { click_zone: 'video', act: 'open-modal' });
    });
    if (this.#popin) {
      // add events listeners once infinite carousel is initialized (because it clones the products)
      this.el.addEventListener('infinite-carousel:init', () => {
        super.initTracking();
      });
    } else super.initTracking();

    this.#popin?.addEventListener('popin:open', () => {
      this.#popinVideo.play();
      this.#video?.pause();
    });
    this.#popin?.addEventListener('popin:close', () => {
      this.#popinVideo.pause();
      this.#video?.play();
    });
    this.#video?.play();

    this.#impulseExpand?.addEventListener('click', this.#onImpulseExpand.bind(this));

    if (this.el.dataset.format == 'impulse') {
      new ImpulseCarousel({ el });
    }
  }

  #onImpulseExpand() {
    if (this.#isExpand) {
      this.el.style.bottom = '-120px';
      this.#impulseExpand.style.transform = 'rotate(180deg)';
      this.#isExpand = false;
      this.#video?.pause();
    } else {
      this.el.style.bottom = '0px';
      this.#impulseExpand.style.transform = 'rotate(0deg)';
      this.#isExpand = true;
      this.#video?.play();
    }
  }

  #onClickImage() {
    if (this.#popin) {
      super.postTracking(this.trackingClick, { click_zone: 'image', act: 'open-modal' });
    } else {
      super.postTracking(this.trackingClick, { click_zone: 'format', act: 'redirect' });
    }
  }
}

componentRegistry.define('js-kamino-impulse', KaminoImpulse);
